// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function downloadJSON(data: any, filenamePrefix: string, setLoading: (loading: boolean) => void): void {
  setLoading(true) // Set exportJsonLoading to true at the start of the download
  try {
    const filename = generateFilename(filenamePrefix, 'json')
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error during file download:', error)
  } finally {
    setLoading(false)
  }
}

export function generateFilename(prefix: string, extension: string): string {
  const titleWithUnderscore = prefix

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')

  const hours = String(currentDate.getHours()).padStart(2, '0')
  const minutes = String(currentDate.getMinutes()).padStart(2, '0')
  const seconds = String(currentDate.getSeconds()).padStart(2, '0')
  const datetime = `${year}${month}${day}_${hours}${minutes}${seconds}`

  return `${titleWithUnderscore}_${datetime}.${extension}`
}
