






























































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { downloadJSON } from '@/utils/fileUtils.ts'

@Component
export default class StockInventoriesWithDiffListReport extends Vue {
  private filters = {
    fromDate: null as string | null,
    toDate: null as string | null,
  }

  private fromDateMenu = false
  private toDateMenu = false
  private exportJsonLoading = false
  private stockInventoriesWithDiffListData = null

  private differencesDialog = false
  private selectedDifferences = []

  private tableHeaders = [
    { text: this.$t('c:stock-details:Site ID'), value: 'siteId' },
    { text: this.$t('c:stock-details:Cost Center Name'), value: 'costCenterName' },
    { text: this.$t('c:stock-details:Cost Center Code'), value: 'costCenterCode' },
    { text: this.$t('c:stock-details:Location'), value: 'location' },
    { text: this.$t('c:stock-details:Date'), value: 'date' },
    { text: this.$t('c:stock-details:Type'), value: 'type' },
    { text: this.$t('c:stock-details:Quantity'), value: 'quantity' },
    { text: this.$t('c:stock-details:Inventory ID'), value: 'inventoryId' },
    { text: this.$t('c:stock-details:Inventory Name'), value: 'inventoryName' },
    { text: this.$t('c:stock-details:Sum Amount Different List'), value: 'sumAmountDifferentList' },
    { text: this.$t('c:stock-details:Sum Cost'), value: 'sumCost' },
    { text: this.$t('c:stock-details:Sum Fee'), value: 'sumFee' },
    { text: this.$t('c:stock-details:Sum Total'), value: 'sumTotal' },
    { text: this.$t('c:stock-details:Differences'), value: 'differences' },
  ]

  private differenceTableHeaders = [
    { text: this.$t('c:stock-details:Product ID'), value: 'productId' },
    { text: this.$t('c:stock-details:Stock Account'), value: 'stockAccount' },
    { text: this.$t('c:stock-details:Cost Account'), value: 'costAccount' },
    { text: this.$t('c:stock-details:Quantity'), value: 'quantity' },
    { text: this.$t('c:stock-details:Amount'), value: 'amount' },
    { text: this.$t('c:stock-details:Performed By'), value: 'performedBy' },
  ]

  private executeReport() {
    this.$axios
      .get('/v4/site/stock/inventories-with-diff-list', { params: this.filters })
      .then((response) => {
        this.stockInventoriesWithDiffListData = response.data.data
      })
      .catch((error) => {
        console.error('Error fetching report:', error)
      })
  }

  private clear() {
    this.filters = {
      fromDate: null,
      toDate: null,
    }
    this.fromDateMenu = false
    this.toDateMenu = false
    this.stockInventoriesWithDiffListData = null
  }

  private handleJsonDownload() {
    downloadJSON(this.stockInventoriesWithDiffListData, 'stock_inventories_with_diff_list', this.setExportJsonLoading)
  }

  private setExportJsonLoading(loading: boolean): void {
    this.exportJsonLoading = loading
  }

  private openDifferencesDialog(differences: any[]) {
    this.selectedDifferences = differences
    this.differencesDialog = true
  }

  private closeDifferencesDialog() {
    this.differencesDialog = false
  }
}
